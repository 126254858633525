@font-face {
  font-family: 'Roboto', 'Dax Light';
  src: url('./fonts/dax_light/dax_light.eot');
  src: url('./fonts/dax_light/dax_light.eot') format('eot'),
    url('./fonts/dax_light/dax_light.woff2') format('woff2'),
    url('./fonts/dax_light/dax_light.woff') format('woff'),
    url('./fonts/dax_light/dax_light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto', 'Dax Medium';
  src: url('./fonts/dax_medium/dax_medium.eot');
  src: url('./fonts/dax_medium/dax_medium.eot') format('eot'),
    url('./fonts/dax_medium/dax_medium.woff2') format('woff2'),
    url('./fonts/dax_medium/dax_medium.woff') format('woff'),
    url('./fonts/dax_medium/dax_medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto', 'Dax Regular';
  src: url('./fonts/dax_regular/dax_regular.eot');
  src: url('./fonts/dax_regular/dax_regular.eot') format('eot'),
    url('./fonts/dax_regular/dax_regular.woff2') format('woff2'),
    url('./fonts/dax_regular/dax_regular.woff') format('woff'),
    url('./fonts/dax_regular/dax_regular.ttf') format('truetype');
}

* {
  /* font-family: 'Dax Regular'; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --baseFontFamily: 'Roboto';
  --baseFontSize: 16px;
  --cardHeaderFontSize: 14px;
  --cardHeaderFontColor: #4545;
  --cardBackgroundColor: #f5f5f5;
  --cardSubHeaderFontSize: 15px;

  --tabFontFamily: 'Roboto';
  --tabFontWeight: 500;
  --tabFontColor: #868686;
  --tabFontSize: 14px;

  --addLinkColor: rgb(36, 0, 255, 0.9);
  --containerBorderColor: rgba(112, 112, 112, 0.2);

  --primary-color: #037ee8;
  --secondary-color: #27d466;
  --gray-light-color: #bdbdbd;
}

::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0px 0px 20px rgb(214, 214, 214);
}

body {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  overflow: hidden;
  /* font-family: 'Dax Regular'; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.sku-template-dropdown-container hr {
  width: 90% !important;
}

.line-item-table-container th {
  text-align: center;
  white-space: normal;
}

.line-item-table-container th svg {
  display: none;
}

.line-item-table-container td {
  text-align: center;
  white-space: normal;
}

.item-table-container {
  width: 100%;
}

.item-table-container th {
  /* text-align: center; */
  white-space: normal;
  color: #4f4f4f;
}

/* .item-table-container th svg {
  display: none;
} */

.item-table-container td {
  /* text-align: center; */
  white-space: normal;
  color: #4f4f4f;
  padding: 0;
}

.SP-Challan-container th {
  text-align: center;
  white-space: normal;
}

.SP-Challan-container th svg {
  display: none;
}

.SP-Challan-container td {
  text-align: center;
  white-space: normal;
}

.voucher-view-container th {
  /* text-align: center; */
  white-space: normal;
}

.voucher-view-container th svg {
  display: none;
}

.voucher-view-container td {
  /* text-align: center; */
  white-space: normal;
}

.voucher-view-container td:hover {
  overflow: visible;
}

.hidden-voucher-table td,
.voucher-list-view td {
  white-space: normal;
}

.bulk-edit-container td,
.sku-print-container th,
.sku-print-container td {
  text-align: center;
  white-space: normal;
}

.sku-print-container th svg {
  display: none;
}

.view-profile-label {
  font-size: 12px;
  color: blue;
  border: 1px solid;
  padding: 0px 11px 0px 0px;
  margin-left: 15px;
  cursor: pointer;
  border-radius: 2px;
}

.details-container {
  /* margin-top: 3px;
  display: grid;
  justify-items: left;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 4fr 4fr 1fr;
  border: 2px solid #f5f5f5;
  background-color: var(--cardBackgroundColor);
  padding-bottom: 0.625rem;
  font-family: roboto; */

  display: grid;
  justify-items: left;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 5fr 3fr 1fr;
  border-radius: 2px solid #f5f5f5;
  background-color: var(--cardBackgroundColor);
  padding: 0.75rem 0;
  font-family: roboto;
  margin: 1.25rem 0 0 0;
}

.details-voucher-container {
  display: block;
  padding: 0.8rem;
}

.details-header,
.item-name-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-header .left {
  display: flex;
  align-items: center;
}

.details-voucher-container .details-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.details-container .name {
  grid-column: 2 / span 3;
  width: 95%;
}

.details-container .edit {
  float: right;
}

.details-container .details-icon {
  height: 25px;
  width: 14px;
  grid-row: span 5;
  align-self: center;
  justify-self: center;
  padding: 0 0.3rem 0 1rem;
}

.details-container .font {
  font-family: roboto;
  font-size: 0.875rem;
  padding: 0.2rem;
  text-overflow: ellipsis;
  color: #000000;
}

.details-container .sub-font {
  font-size: 0.8rem;
  color: #868686;
  font-style: italic;
}

.details-container .sub-font-right {
  justify-self: right;
  padding-right: 0.75rem;
}

.details-container .close-icon {
  height: 0.6875rem;
  width: 0.6875rem;
  padding: 0.4rem;
  align-self: start;
  align-items: center;
  cursor: pointer;
}

.text-input input {
  font-size: 14px;
}

/* .item-add-stock {
  font-style: italic;
  padding-top: 16px !important;
  margin: 0px !important;
}

.item-add-stock p {
  font-size: 12px !important;
} */

.item-sales-price-icon {
  font-size: 12px;
  font-style: italic;
  font-weight: 100;
}

.purchase-details-container {
  margin-bottom: 3.5rem;
}

.sales-price p {
  margin-top: 25px !important;
}

.add-item-radio-buttoms {
  padding-top: 5px;
  text-align: left;
}

.add-item-radio-buttoms label {
  font-size: 14px;
  font-family: 'Roboto', Medium;
}

.item-edit-link {
  padding: 10px !important;
}

.item-edit-link-icon {
  font-size: 13px;
  font-style: italic;
  padding: 30px 0px 0px !important;
  margin-left: 0px !important;
}

.total-summary-block {
  padding: 5px 20px 8px;
  background: #f5f5f5;
  width: 100%;
}

.grid-toolbar {
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  color: black;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;
  align-items: flex-end;
}

tr td {
  border-bottom: none !important;
  overflow: hidden;
  white-space: normal;
}

/* tr td:hover {
  overflow: visible;
} */

th {
  font-size: 0.83rem !important;
}

.collapse-div-icon {
  position: absolute;
  cursor: pointer;
  top: 40vh;
  margin-left: -1rem;
}

.expand {
  position: absolute;
  cursor: pointer;
  top: 35vh;
  margin-left: -2.8rem;
}

.expandView {
  width: calc(100% - 20px);
  transition: width 0.6s;
  overflow: hidden;
  position: relative;
}

.add-voucher-collapse-div-icon {
  position: absolute;
  cursor: pointer;
  top: 40vh;
  left: -16px;
  z-index: 1;
}

.file-upload-label {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 64px;
  width: 64px;
  color: #888888;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  background-color: rgba(181, 181, 181, 0.2);
  border: 1px solid #eee;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-top: 5px;
  flex-direction: column;
}

.view-profile-block:hover span {
  color: var(--primary-color);
}

.view-profile-block {
  display: flex;
  cursor: pointer;
}

.view-profile-block i {
  margin: 0 8px;
  color: var(--primary-color);
  font-size: 20px;
}

.file-upload-label:hover {
  box-shadow: none;
  transition: box-shadow 600ms none;
}

.fileInput {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  opacity: 0;
}

.preview-container {
  margin: 0px 10px 0 0;
}

.preview-container img {
  width: 10rem;
  height: 5rem;
}

.loader-container {
  width: 10rem;
  height: 5rem;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  text-align: center;
}

.loader-block {
  padding-top: 20px;
}

.thumbnail-container {
  position: relative;
  width: 5rem;
  height: 5rem;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.thumbnail-container:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}

.thumbnail-container img {
  position: absolute;
  width: 5rem;
  height: 5rem;
  left: 0;
  border-radius: 5px;
}

.delete-button {
  position: absolute;
  width: 100%;
  left: 0;
  top: 25px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}

/* .delete-button i {
  color: #ce1010;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
} */

.thumbnail-container:hover .delete-button {
  opacity: 1;
}

.di-filter-header {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.partyname {
  padding: 8px 6px;
}

.filter-button,
.position-relative {
  position: relative;
}

.applied-filter {
  position: absolute;
  left: 5px;
  top: -8px;
  background: var(--secondary-color);
  color: #fff;
  border-radius: 50% 50%;
  width: 14px;
  height: 14px;
}

.filter-block:hover {
  background: #dadada;
}

.filter-clear {
  font-style: italic;
  margin-right: 25px;
  font-size: 12px;
  color: blue;
  cursor: pointer;
}

.uploadFile-name-block {
  margin: 12px 0;
  word-break: break-word;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.rate-code-accumulator {
  width: 35px;
  background: none;
  border: none;
  height: 20px;
  margin: 30px 7px 0;
  border-bottom: 1px solid lightgrey;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 10px;
}

/* label {
  line-height: 15px !important;
} */

#party-statement-block tr th,
#party-statement-block tr td {
  padding: 4px 18px 4px 0px !important;
}

.error-msg-block {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.table-summary-block {
  display: flex;
  margin-top: 15px;
  width: 90%;
  border-top: 1px solid #dbdbdb;
  padding: 10px;
  padding-left: 8%;
}

.fin-year-block {
  padding: 8px 20px;
  border: 1px solid #bcbfc7;
  border-radius: 25px;
  font-size: 14px;
  bottom: 4px;
  /* position: relative; */
  /* margin-left: 20px; */
}

.stv-radio-tabs-wrapper {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 30px;
  display: flex;
}

input.stv-radio-tab {
  position: absolute;
  left: -99999em;
  top: -99999em;
}

input.stv-radio-tab:checked + label {
  border: 1px solid #428bca;
  /* z-index: 1; */
  color: white;
  background: #428bca;
}

input.stv-radio-tab + label {
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 13px;
}

input.stv-radio-tab label {
  cursor: pointer;
  float: left;
  border: 1px solid #aaa;
  border-bottom: 0;
  background-color: #fff;
  margin-right: -1px;
  padding: 0.5em 1em;
  position: relative;
}

input.stv-radio-tab label:hover {
  background-color: #eee;
}

/* ListButton */

.listbutton {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px 30px 10px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  height: 18px;
  display: flex;
  color: #2f80ed;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 20px;
  text-align: center;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  justify-content: center;
  border: 1px solid #2f80ed;
  background-color: #ffffff;
  font-weight: 400;
  margin-right: 10px;
}

.dd-button-half-square {
  border-radius: 0px 100px 100px 0px;
  background-color: #27d466;
  border: 1px solid #27d466;
  color: #fff;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.dd-button-half-square:hover {
  background-color: #20b757;
  border: 1px solid #20b757;
}

.dd-button-half-square:disabled {
  background-color: #7e7e7e;
  border: 1px solid #7e7e7e;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
  z-index: 1;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  color: #428bca;
  white-space: nowrap;
  font-size: 14px;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.suggestion-gstType {
  color: #4a96d3;
  font-size: 10px;
  font-style: italic;
  border: 1px solid;
  border-radius: 5px;
  padding: 2px 8px;
  text-transform: capitalize;
}

.width-100 {
  width: 100%;
}

.share-voucher-view-block {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 15px 0px 0px 0px;
  position: relative;
  overflow: auto;
}

.share-text-block {
  font-size: 14px;
  cursor: unset;
  color: #000000;
  font-weight: 400;
  text-transform: capitalize;
  font-family: Roboto, Medium;
}

.share-block {
  width: 30%;
}

.share-header-text-block {
  font-size: 16px;
}

.share-text-block-mob {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.share-block-mob {
  width: 45%;
}

.share-header-text-block-mob {
  font-size: 36px !important;
}

.share-doc-block img {
  height: 30rem;
}

.share-table-mob th {
  font-size: 36px !important;
  text-align: center;
}

.share-table-mob td {
  font-size: 30px !important;
  text-align: center;
}

#party-statement-block-table thead th {
  /* text-align: center !important; */
  white-space: normal;
}

.party-statement-block-table tr td {
  /* text-align: center !important; */
  white-space: normal !important;
}

#party-statement-block-table thead th svg {
  display: none !important;
}

#party-statement-block-table thead th svg:nth-child(1) {
  display: inline-block !important;
}

button {
  white-space: nowrap;
}

.public-link {
  background: #eaeaea;
  padding: 5px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip {
  width: 200px;
}

.fa-eye,
.fa-pencil,
.fa-times {
  cursor: pointer;
}

tbody tr:hover,
.voucher-list-card:hover {
  background: #eeeeee !important;
}

.item-list-table tbody tr:hover,
.item-list-table tbody tr:focus-within {
  background: #eeeeee;
}

/* .item-list-table tbody td:hover, */
.item-list-table tbody td:focus-within div {
  background: #fff;
}

#party-statement-block {
  background: #f4f5f7;
  margin-bottom: 15px;
}

ul.react-paginate {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.react-paginate li {
  display: inline-block;
  font-size: 0.875rem;
  padding: 6px 8px;
  cursor: pointer;
}

.react-paginate li.active {
  font-weight: bold;
  cursor: default;
}

.react-paginate li.disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
}

.react-paginate li:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}

.rc-menu-dashboard {
  border-radius: 0 !important;
}

.rc-menu-dashboard .rc-menu-submenu-title {
  margin: 0;
  position: relative;
  display: block;
  padding: 0 !important;
  white-space: nowrap;
  background-color: white !important;
  color: #868686;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 1rem;
}

.rc-menu-horizontal > .rc-menu-submenu {
  border-bottom: none !important;
}

.rc-menu-dashboard .rc-menu-submenu-active {
  border-bottom: none !important;
  background-color: white !important;
  color: #666 !important;
}

.rc-menu-submenu {
  z-index: 1000;
}

/* .rc-menu-dashboard .rc-menu-submenu {
  border-bottom: none !important;
} */

.rc-menu-dashboard-sub .rc-menu-vertical.rc-menu-sub,
.rc-menu-dashboard-sub .rc-menu-vertical-left.rc-menu-sub,
.rc-menu-dashboard-sub .rc-menu-vertical-right.rc-menu-sub {
  max-height: 21rem !important;
  overflow-y: auto !important;
  min-width: 14rem;
  max-width: 25rem;
}

.rc-menu-dashboard-sub .dash-menu-section {
  font-size: 1rem;
  color: #4f4f4f;
  background-color: #f2f2f2;
  padding: 5px 12px;
}

.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4f4f4f;
  font-size: 0.75rem;
  border-radius: 5px;
}

.rc-menu-vertical .dash-menu-item {
  color: #4f4f4f;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.rc-menu-vertical .dash-menu-subitem {
  width: 92%;
}

.rc-menu-vertical .rc-menu-item {
  padding: 0 0 0 0 !important;
}

.rc-menu-vertical .rc-menu-submenu-title {
  padding: 0 0 0 0 !important;
  display: flex;
  align-items: center;
}

.submenu-title-wrapper {
  padding: 12px 8px 12px 12px;
  display: block;
}

.rc-menu-vertical .rc-menu-item-active,
.rc-menu-vertical .rc-menu-submenu-active {
  background-color: white;
}

.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: white !important;
}

.rc-menu-vertical .dash-menu-item i {
  padding: 12px 8px 12px 12px;
  border-radius: 4px;
  visibility: visible;
}

.rc-menu-vertical .dash-menu-item .dash-menu-item-text {
  padding: 12px 8px 12px 12px;
  border-radius: 4px;
  flex: 1;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.rc-menu-vertical .dash-menu-item .dash-menu-item-text:hover {
  background-color: #eaf8fe;
}

.rc-menu-vertical .dash-menu-item i:hover {
  background-color: #eaf8fe;
  visibility: visible;
}

.rc-menu-vertical .expand-arrow {
  display: inline-block;
  font-size: 0.55rem;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: 16px;
  line-height: 1rem;
}

.rc-menu-vertical .rc-menu-item-group-title {
  padding: 0;
}

.rc-menu-vertical .rc-menu-item-group-list {
  max-height: 250px;
  overflow-y: auto;
}

.dash-tab:hover {
  border-bottom: 3px solid var(--primary-color);
}

.rp-grid-table {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: center;
}

.rp-grid-table-item {
  min-width: 300px;
}

.tab-container-block {
  height: 60vh;
}

.party-header {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 1.875rem 0;
  box-shadow: none;
  flex-wrap: wrap;
  align-items: center;
}

.party-header .left-items {
  margin-left: auto;
}

.pos-table-filter {
  z-index: 1500 !important;
}

.filter-modal .filter-body {
  padding: 1.2rem;
  padding-top: 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}

.filter-modal .filter-item {
  margin: 10px;
}

.action-block-container {
  margin-left: auto;
}

.actions-block {
  flex-wrap: wrap;
}

.actions-block .action-block-item,
.filter-eye-button {
  margin: 10px;
}

.row {
  display: flex;
  flex-wrap: nowrap;
}

.col-5 {
  width: 20%;
}

.col-4 {
  width: 25%;
}

.col-3 {
  width: 33%;
}

.col-2 {
  width: 50%;
}

.col-1 {
  width: 100%;
}

.mt-4 {
  margin-top: 1rem;
}

.party-total-header-label {
  font-weight: 500;
  margin-right: 7px;
}

.party-list-filter {
  display: flex;
  flex-wrap: wrap;
}

.buttonBack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.buttonNext {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.buttonBack,
.buttonNext {
  background: #6f6e6e;
  border: 1px solid #6f6e6e;
  border-radius: 50%;
  padding: 4px 8px;
  opacity: 0.8;
  font-size: 12px;
  color: white;
}

.buttonNext {
  margin-right: 5px;
}

.buttonBack {
  margin-left: 5px;
}

.MuiFormLabel-root {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
}

.virtual-party-statement-block-table {
  height: 66vh !important;
}

.virtual-party-statement-block-table-filter {
  height: 58vh !important;
}

.reports-table {
  height: 70vh;
}

.material-autocomplete label + .MuiInput-formControl {
  margin-top: 12px;
}

.image-container {
  display: grid;
  place-items: center;
  height: 80vh;
}

.image-container .image {
  object-fit: contain;
}

.image-container img {
  height: 80vh;
  background-color: #444;
}

.image-container img[class] {
  width: 100%;
}

.guide-image {
  object-fit: scale-down;
  transform: scale(0.75, 0.75);
}

.import-label {
  margin: 20px 20px 0px;
  width: 94%;
  border-bottom: 1px solid #a6a6a6;
  text-transform: capitalize;
  line-height: 27px;
  font-weight: 500;
  color: grey;
}

.direct-contact-block {
  /* padding-top: 1rem; */
  margin: 15px 0px;
  border: 1px solid #bababa;
  /* padding: 20px; */
  border-radius: 5px;
}

.variant-loader-block {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-style: italic;
}

.Mui-selected {
  background-color: #eeeeee !important;
}

.switch-label {
  font-size: 11px;
  color: #4f4f4f;
}

.vouchers-linked-tally-company {
  display: flex;
  margin-left: 10px;
  align-items: end;
}

.vouchers-linked-tally-name {
  max-width: 200px;
  display: flex;
  align-items: end;
}

.table-item-footer {
  margin-left: auto;
  margin-right: 0.825rem;
}

.printBSPL-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
}

.BSPLtable-footer tfoot {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.BSPLtable-footer tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.percentage-value {
  margin-left: 2.5rem;
}

.align-flex-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.internet-drop-container,
.text-center {
  text-align: center;
}

.internet-drop-icon {
  fill: var(--gray-light-color);
}

.render-cell-table-container {
  text-align: center;
  box-sizing: border-box;
}

.align-flex-baseline {
  align-items: baseline;
  display: flex;
}

.catalogue-item-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.catalogue-item-buttons .selected-item-btn {
  font-size: 15px;
  margin: 0 auto;
  cursor: pointer;
}

.view-selected-button {
  margin: 0 10px;
}

.e-invoice-ewaybill {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
}

.opacity-light {
  opacity: 0.5;
}

@media screen and (min-height: 500px) {
  .tab-container-block {
    height: 54vh;
  }

  .virtual-party-statement-block-table {
    height: 45vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 44vh !important;
  }

  .reports-table {
    height: 44vh;
  }
}

.profile-buttons {
  width: 4rem;
  padding: 0 0 0.8rem 0.5rem;
}

.mx-auto {
  margin: 0 auto;
}

.dialog-title {
  padding: 1rem 0;
  display: flex;
  font-size: 0.875rem;
  color: #000000;
  border-bottom: 0.0625rem solid #000000;
  align-items: center;
}

.align-pos-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.align-pos-button button:nth-child(2) {
  margin-left: 5px;
}

.account-form {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 1.875rem 3.75rem;
}

.account-form:first-child {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
}

.account-form > :nth-child(n) {
  min-width: 50%;
}

.oc-wrapper.rs-percent-button {
  width: 12px;
  height: 12px;
  left: 10px;
}

.oc-container {
  width: 150px;
}

.oc-container .sub-container {
  width: 100%;
}

.oc-container .rs-text {
  font-size: 14px;
  margin-top: 13px;
  margin-right: 4px;
  color: #868686;
  width: 20;
}

.oc-rs-percent-button {
  display: flex;
  width: 100px;
  align-items: end;
}

.fast-party-icon,
.quick-party-icon {
  cursor: pointer;
  margin-right: 20px;
  width: 1.2rem;
  height: 1.2rem;
}

.quick-party-icon {
  margin-right: 0.5rem;
  color: inherit;
}

.fast-party-icon.active {
  color: rgba(66, 133, 244, 1);
}

.MuiInput-underline:after {
  border-bottom-color: #428bca !important;
}

.interest-icon-info {
  cursor: pointer;
  margin-left: 10px;
}

.inner-carousal-slider {
  position: relative !important;
}

.justify-flex-center {
  display: flex;
  justify-content: center;
  height: 100%;
}

.small-italic-text {
  font-size: 12px;
  font-style: italic;
  color: #7a7a7a;
  padding-left: 10px;
}

.amount-in-words-container {
  width: 30rem;
  display: flex;
  margin-left: auto;
}
.catalog-filter-no-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  font-size: 15px;
}

.font-weight-bold {
  font-weight: bold;
}

.line-item-discount {
  display: flex;
  width: fit-content;
  margin: auto;
}

@media screen and (min-height: 600px) {
  .tab-container-block {
    height: 60vh;
  }

  .virtual-party-statement-block-table {
    height: 52vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 51vh !important;
  }

  .reports-table {
    height: 53vh;
  }
}

@media screen and (min-height: 650px) {
  .tab-container-block {
    height: 62vh;
  }

  .virtual-party-statement-block-table {
    height: 54vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 53vh !important;
  }

  .reports-table {
    height: 57vh;
  }
}

@media screen and (min-height: 700px) {
  .tab-container-block {
    height: 72vh;
  }

  .virtual-party-statement-block-table {
    height: 56vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 54vh !important;
  }

  .reports-table {
    height: 60vh;
  }
}

@media screen and (min-height: 740px) {
  .tab-container-block {
    height: 72vh;
  }

  .virtual-party-statement-block-table {
    height: 58vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 57vh !important;
  }

  .reports-table {
    height: 62vh;
  }
}

@media screen and (min-height: 850px) {
  .tab-container-block {
    height: 72vh;
  }

  .virtual-party-statement-block-table {
    height: 62vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 61vh !important;
  }

  .reports-table {
    height: 67vh;
  }
}

@media screen and (min-height: 900px) {
  .tab-container-block {
    height: 75vh;
  }

  .virtual-party-statement-block-table {
    height: 66vh !important;
  }

  .virtual-party-statement-block-table-filter {
    height: 64vh !important;
  }

  .reports-table {
    height: 68vh;
  }
}

@media screen and (max-width: 1600px) {
  #add-contact-modal-drawer .MuiDrawer-paper {
    width: 80% !important;
  }
}

@media screen and (max-width: 1550px) {
  #add-contact-modal-drawer .MuiDrawer-paper {
    width: 83% !important;
  }
}

@media screen and (max-width: 1500px) {
  #add-contact-modal-drawer .MuiDrawer-paper {
    width: 85% !important;
  }
}

@media screen and (max-width: 1400px) {
  #add-contact-modal-drawer .MuiDrawer-paper {
    width: 90% !important;
  }
}

@media screen and (max-width: 1300px) {
  #add-contact-modal-drawer .MuiDrawer-paper {
    width: 96% !important;
  }
}

@media screen and (max-width: 1200px) {
  #add-contact-modal-drawer .MuiDrawer-paper {
    width: 100% !important;
  }
}
